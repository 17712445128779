import { defineStore } from "pinia";
import { useClientFetch } from "../composables/useClientFetch";
export const useEmailStore = defineStore({
	id: 'email',
	state: () => ({
	}),
	getters: {
	},
	actions: {
		async fetchCountries(): Promise<any> {
			return await useClientFetch('data/locations/countries') || [];
		},
	}
});
