<script setup>


const isVisible = ref(false);

const alertClass = computed(() => (isVisible.value ? 'opacity-100' : 'opacity-0'));

// Show the alert for a few seconds (e.g., 3 seconds)
const showAlert = () => {
  setTimeout(() => {
    isVisible.value = true;
  }, 100);
  setTimeout(() => {
    isVisible.value = false;
  }, 4500);
};
showAlert()
</script>
<template>
   
        <div :class="['w-full px-2 py-4 text-white opacity-0 rounded-md transition-all duration-500',alertClass]">
          <slot>message</slot>
        </div>
    
</template>