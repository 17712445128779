<script lang="ts">
    import {ref, onMounted, type Ref} from 'vue'
    import { useEmailStore } from "~/stores/email.store";
    import { useUserStore } from "~/stores/user.store";
    import { object, string, type InferType } from 'yup';
    import type { FormSubmitEvent } from '#ui/types';
    import { useClientFetch } from '~/composables/useClientFetch';

    export default {
        props: {
            listing: {
                type: Object,
            }
        },
        async setup(props) {
            const emailStore = useEmailStore();
            const userStore = useUserStore();
            const countries: Ref<{[key: string]: any}[]> = ref([]);
            const selectedCountry: Ref<any> = ref({});
            const { isDesktop } = useDevice();

            const user = ref(userStore.user || undefined)
            onMounted(async () => {
                countries.value = await emailStore.fetchCountries();
                if (user && user?.whatsapp) {
                    selectedCountry.value = countries.value?.find((c) => user.value?.country_id == c._id);
                }else {
                    selectedCountry.value = countries.value[0];
                }
            });
            const schema = object({
                name: string().required('The name field is required.'),
                email: string().email("Invalid email address"),
                phone: string().required('The phone field is required.'),
                body: string().required('The body field is required.'),
            });
            type Schema = InferType<typeof schema>


            const state = ref({
                name: user.value ? `${user.value?.first_name} ${user.value?.last_name}` : undefined,
                email: user.value ? user.value?.email : undefined,
                phone: user.value?.whatsapp || undefined,
                body: props.listing ? props.listing.default_message : undefined,
                listing_id: props.listing ? props.listing._id : undefined
            });

            const alert = ref({
                status: false,
                message: "",
                type: "",
            })

            const onSubmit = async (event: FormSubmitEvent<Schema>) => {
                event.preventDefault()
                const res: any =  await useClientFetch('pages/contact-us', 'POST', {
                    body: {
                        ...state.value,
                        country_name: selectedCountry.value.name,
                        phone_code: selectedCountry.value?.phone_code,
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).catch(err => {
                    alert.value.type = "error";
                    alert.value.status=true;
                    alert.value.message = err.response._data.message || 'Something Went Wrong Please Try Again';
                    showAlert();
                })

                if (res?.status == 201) {
                    alert.value.type = "success";
                    alert.value.status = true;
                    alert.value.message = res.message;
                    state.value = {
                        name: user.value ? `${user.value?.first_name} ${user.value?.last_name}` : undefined,
                        email: user.value ? user.value?.email : undefined,
                        phone: user.value?.whatsapp || undefined,
                        body: props.listing ? props.listing.default_message : undefined,
                        listing_id: props.listing ? props.listing._id : undefined
                    };
                    selectedCountry.value = countries.value[0];
                    showAlert();
                }

            }

            const showAlert = () => {
                setTimeout(() => {
                    alert.value.status = false;
                }, 5000);
            }

            const update = ($event: any) => {
                if (!selectedCountry.value) {
                    selectedCountry.value = {}
                }

                selectedCountry.value = countries.value.find(c => c.phone_code == $event);
            }

            return { state, alert, onSubmit, schema, selectedCountry, countries, user, isDesktop, update }
        }
    }
</script>

<template>
    <form :class="{ [isDesktop ? '' : 'w-full']: true }" @submit="onSubmit">
        <div class="my-5 relative"
        :class="{ [isDesktop ? 'grid grid-cols-6 gap-x-3 gap-y-1' : 'flex flex-col gap-3']: true }"
        >
            <div class="col-span-3">
                <div class="mt-1"
                :class="{ [isDesktop ? 'mb-3' : '']: true }"
                >
                    <WInput 
                        class="bg-white rounded-md ring-1 ring-gray-200"
                        type="text" 
                        name="name"
                        style=""
                        :disabled="user"
                        :placeholder="$t('client.name')"
                        v-model:value="state.name" 
                    />
                </div>
            </div>
            <div class="col-span-3">
                <div class="mt-1">
                    <WInput 
                        class="bg-white rounded-md ring-1 ring-gray-200"
                        type="email" 
                        name="email"
                        style=""
                        :placeholder="$t('client.email')"
                        :disabled="user"
                        v-model:value="state.email" 
                    />
                </div>
            </div>
            <div class="col-span-2" v-if="selectedCountry.phone_code">
                <WInput 
                    :select="true"
                    :options="countries"
                    class="bg-white rounded-md ring-1 ring-gray-200"
                    type="select" 
                    name="country"
                    style=""
                    :placeholder="$t('client.country')"
                    :disabled="!!user?.whatsapp"
                    trackBy="phone_code"
                    label="name"
                    v-model:value="selectedCountry"
                    @update:value="update"
                />
            </div>
            <div class="col-span-4">
                <WInput 
                    class="bg-white rounded-md ring-1 ring-gray-200"
                    type="text" 
                    name="phone"
                    style=""
                    :placeholder="$t('client.phone')"
                    :disabled="!!user?.whatsapp"
                    v-model:value="state.phone" 
                />
            </div>
            <div class="col-span-full mb-3">
                <WInput 
                    class="bg-white rounded-md ring-1 ring-gray-200"
                    type="textarea" 
                    name="phone"
                    style=""
                    :placeholder="$t('client.enter_your_messages')"
                    v-model:value="state.body" 
                />
            </div>
            <div class="col-span-full">
                <w-button type="submit" class="w-full block text-base rounded-md bg-indigo-900 sm:px-0.5 md:px-2 py-3  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    {{ $t("client.send_message") }}
                </w-button>
            </div>

            <w-alert
                v-if="alert.status && alert.type == 'success'"
                class="absolute bottom-0 left-0 bg-green-500"
            
            >{{ alert.message }}</w-alert>
            <w-alert
                v-if="alert.status && alert.type == 'error'"
               class="absolute bottom-0 left-0 bg-red-500"
            >
            {{ alert.message }}
            </w-alert>
        </div>
    </form>
</template>